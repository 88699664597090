<template>
    <div>
        <div class="creator_detail_wrap pl0" v-if="getMemberType() === 'curator'">
            <div class="creator_detail_tab_wrap has_bottom">
                <div class="creator_detail_tab" :class="{ active : myType === 'curator'}" @click="changeTab('curator')">
                    <span class="tab_text">{{ $t('premium') }}</span>
                </div>
                <div class="creator_detail_tab" :class="{ active : myType === 'normal'}" @click="changeTab('normal')">
                    <span class="tab_text">{{ $t('normal') }}</span>
                </div>
            </div>
        </div>
        <item-list-component v-if="myType === 'curator'" :item-type="'itemCuratorAdd'"
                             :memberKey="UserInfo.mb_no"></item-list-component>
        <item-list-component v-else-if="myType === 'normal'" :item-type="'itemAdd'"></item-list-component>
    </div>
</template>

<script>
import alertMixins from "@/mixins/alertMixins";
import galleryValidator from "@/mixins/validators/galleryValidator";
import ItemListComponent from "@/components/item/ItemListComponent";
import {mapState} from "vuex";
import util from "@/mixins/util";
import EventBus from "@/utils/event-bus";
import AuthMixins from "@/mixins/AuthMixins";

export default {
    name: "GalleryWriteItemAddLayout",
    mixins: [galleryValidator, alertMixins, AuthMixins],
    components: {
        ItemListComponent,
    },
    inject: ['galleryWriteSetData'],
    provide() {
        return {
            setSelectItem: this.selectItemKey,
        }
    },
    props: {
        galleryData: {
            default: () => {
            }
        },
    },
    data() {
        return {
            itemType: '',
            myType: 'normal',
        }
    },
    beforeRouterEnter() {
    },
    created() {
        let myType = this.getMemberType();
        if (!util.isEmpty(this.$route.query.tab)) {
            myType = this.$route.query.tab;
        }
        this.myType = myType;
        /*if(this.myType === 'curator') {
            this.itemType = 'itemCuratorAdd';
        }*/
        if (this.galleryData.mb_no !== this.UserInfo.mb_no) {
            this.$router.push(`/gallery/${this.$route.params.idx}`);
        }
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            UserInfo: state => state.userInfo.UserInfo,
        })
    },
    methods: {
        selectItemKey(item) {
            if (util.isEmpty(item.i_idx)) {
                return false;
            }
            this.itemKey = item.i_idx;
            this.galleryWriteSetData('itemKey', this.itemKey);
            this.createConfirm({
                content: `'<span class="subject">${item.i_name}</span>'<br>${this.$t('collection_item_add_confirm1')}`,
                confirm: () => {
                    this.checkItem(item);
                },
                // content: `'<span class="subject">${this.itemname}</span>'<br>${this.$t('collection_item_add_confirm1')}`
            })
        },
        checkItem(item) {
            //"ibc_status": null, //0:대기,1:완료,2:거절,3:의뢰취소,4:판매완료,5:의뢰포기
            let itemStatus = [0, 1, 4];
            if (this.myType === 'normal' && (itemStatus.indexOf(item.ibc_status) > -1)) {
                this.errorAlert(this.$t('collection_item_add_err5'));
                return false;
            }
            EventBus.$emit('writeGallery')
        },
        changeTab(type) {
            if (this.myType === type) {
                return false;
            }
            this.myType = '';
            this.$nextTick(() => {
                this.myType = type;
                this.$router.replace(this.$route.path + '?tab=' + type);
            })
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
